<template>
  <b-overlay
      :show="Options === null"
      rounded="sm"
  >
    <div v-if="Options !== null">
      <b-modal
          id="modal-delete"
          cancelTitle="خیر"
          centered
          ok-title="بله"
          @ok="DeleteOption"
      >
        <span>آیا از حذف کردن این ویژگی اطمینان دارید ؟ </span>
      </b-modal>
      <b-modal
          id="modal-edit"
          centered
          ok-only
          ok-title="بروزرسانی ویژگی"
          @ok.preventDefault()="UpdateOption"
      >
        <b-row v-if="SelectedOption!==null">
          <b-col cols="12">
            <b-form-group>
              <label>نام ویژگی</label>
              <b-form-input
                  v-model="SelectedOption.name"
                  placeholder="نام ویژگی"
                  type="text"
              />
            </b-form-group>

          </b-col>
        </b-row>


      </b-modal>
      <b-modal
          id="modal-Add"
          centered
          hide-footer
          ok-only
          size="xl"
      >
        <b-row>
          <b-col class="my-2" lg="4" md="12">
            <b-form-group
                label="نام"
                label-for="defaultLabel"
            >
              <b-form-input
                  id="defaultLabel2"
                  v-model="optionValueName"
                  placeholder="نام"
              />
            </b-form-group>
            <b-button
                variant="primary"
                @click="CreateOptionValue"
            >
              ثبت
            </b-button>
          </b-col>
          <b-col class="my-2" lg="8" md="12">
            <b-row>
              <b-col cols="12" lg="4" class="mb-1 ml-auto">
                <clear-sorts-btn @clear="sortBy = ''"></clear-sorts-btn>
              </b-col>
              <b-col cols="12" >
                <b-table
                    ref="refUserListTable"
                    :fields="ValuesColumns"
                    :items="OptionValues"
                    bordered
                    :sort-by.sync="sortBy"
                    class="position-relative"
                    empty-text="موردی موجود نیست!"
                    primary-key="id"
                    responsive
                    show-empty
                    striped
                >

                  <!-- Column: delete -->

                  <template #cell(operation)="data">
                    <div class="d-flex align-items-center justify-content-between">

                      <div v-b-modal.modal-delete-value
                           class="cursor-pointer d-flex flex-row"
                           @click="SetSelectedOptionValue(data.item)"
                      >
                        <feather-icon class="text-danger" icon="TrashIcon" size="20"/>
                      </div>
                      <div v-b-modal.modal-edit-value
                           class="cursor-pointer d-flex flex-row"
                           @click="SetSelectedOptionValue(data.item)"
                      >
                        <feather-icon class="text-primary" icon="EditIcon" size="20"/>
                      </div>
                    </div>
                  </template>


                </b-table>
              </b-col>
            </b-row>
          </b-col>

        </b-row>


      </b-modal>
      <b-modal
          id="modal-delete-value"
          cancelTitle="خیر"
          centered
          ok-title="بله"
          @ok="DeleteOptionValue"
      >
        <span>آیا از حذف کردن این مقدار ویژگی اطمینان دارید ؟ </span>
      </b-modal>
      <b-modal
          id="modal-edit-value"
          centered
          ok-only
          ok-title="بروزرسانی مقدار ویژگی"
          @ok.preventDefault()="UpdateOptionValue"
      >
        <b-row v-if="SelectedOptionValue!==null">
          <b-col cols="12">
            <b-form-group>
              <label>نام مقدار ویژگی</label>
              <b-form-input
                  v-model="SelectedOptionValue.value"
                  placeholder="نام مقدار ویژگی"
                  type="text"
              />
            </b-form-group>

          </b-col>
        </b-row>


      </b-modal>
      <!-- Table Container Card -->
      <b-card
          class="mb-0"
          no-body
      >
        <b-col class="my-2" lg="12" md="12">
          <b-row>

            <b-col class="my-2" lg="4" md="12">
              <b-form-group
                  label="نام"
                  label-for="defaultLabel"
              >
                <b-form-input
                    id="defaultLabel"
                    v-model="optionName"
                    placeholder="نام"
                />
              </b-form-group>
              <b-button
                  variant="primary"
                  @click="CreateOption"
              >
                ثبت
              </b-button>
            </b-col>
            <b-col class="my-2" lg="8" md="12">
              <b-row>
                <b-col cols="12" lg="4" class="mb-1 ml-auto">
                  <clear-sorts-btn @clear="sortBy = ''"></clear-sorts-btn>
                </b-col>
                <b-col cols="12">
                  <b-table
                      ref="refUserListTable"
                      :fields="myTableColumns"
                      :items="Options"
                      :sort-by.sync="sortBy"

                      bordered
                      class="position-relative"
                      empty-text="موردی موجود نیست!"
                      primary-key="id"
                      responsive
                      show-empty
                      striped
                  >

                    <!-- Column: delete -->

                    <template #cell(operation)="data">
                      <div class="d-flex align-items-center justify-content-between">

                        <div v-b-modal.modal-delete
                             class="cursor-pointer d-flex flex-row"
                             @click="SetSelectedOption(data.item)"
                        >
                          <feather-icon class="text-danger" icon="TrashIcon" size="20"/>
                        </div>


                        <div v-b-modal.modal-edit
                             class="cursor-pointer d-flex flex-row"
                             @click="SetSelectedOption(data.item)"
                        >
                          <feather-icon class="text-primary" icon="EditIcon" size="20"/>
                        </div>
                        <div v-b-modal.modal-Add
                             class="cursor-pointer d-flex flex-row"
                             @click="SetSelectedOption(data.item)"
                        >
                          <feather-icon class="text-success" icon="GridIcon" size="20"/>
                        </div>
                      </div>
                    </template>


                  </b-table>

                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-card>
    </div>


  </b-overlay>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormFile,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BModal, BFormGroup, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import {
  CreateOptionValue,
  CreateOption,
  DeleteOptionValue,
  DeleteOption,
  UpdateOptionValue,
  UpdateOption,
  GetAllOptionValuesByOptionId,
  GetAllOptions
} from "@/libs/Api/optionValues";
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {getUserData} from "@/auth/utils";
import ClearSortsBtn from "@/views/components/clearSortsBtn.vue";

export default {
  title: 'ویژگی های محصولات  - پنل ادمین انزا مد',
  name: 'UsersList',
  data() {
    return {
      Options: null,
      OptionValues: null,
      optionName: '',
      optionValueName: '',
      sortBy: '',
      ValuesColumns: [
        {
          key: 'optionValueId',
          label: 'شناسه',
          sortable: true
        },
        {
          key: 'value',
          label: 'نام',
          sortable: true
        },
        {
          key: 'operation',
          label: 'عملیات'
        },


        // { key: 'parentId'},
      ],
      myTableColumns: [
        {
          key: 'optionId',
          label: 'شناسه',
          sortable: true
        },
        {
          key: 'name',
          label: 'نام',
          sortable: true
        },
        {
          key: 'operation',
          label: 'عملیات'
        },

        // { key: 'parentId'},
      ],
      currentPage: 1,
      CategoryName: '',
      search: '',
      SelectedOption: null,
      SelectedOptionValue: null

    }
  },
  async created() {
    await this.GetAllOptions();
  },
  components: {
    ClearSortsBtn,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
  },

  methods: {
    async UpdateOptionValue() {
      let _this = this
      let updateOptionValue = new UpdateOptionValue(_this)
      let data = {
        optionValueId: this.SelectedOptionValue.optionValueId,
        optionId: this.SelectedOption.optionId,
        value: this.SelectedOptionValue.value,
        productId: 0,
        optionValueParentId: null,
        vendorUserId: getUserData().userId
      }
      updateOptionValue.setData(data)
      await updateOptionValue.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `مقدار ویژگی بروزرسانی شد`,
          },
        })
        _this.GetOptionValuesByOptionId();
      }, function (error) {
        console.log(error)
      })
    },
    async DeleteOptionValue() {
      let _this = this
      let deleteOptionValue = new DeleteOptionValue(_this)
      deleteOptionValue.setParams({id: this.SelectedOptionValue.optionValueId})
      await deleteOptionValue.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `مقدار ویژگی حذف شد.`,
          },
        })
        _this.GetOptionValuesByOptionId();
      }, function (error) {
        console.log(error)
      })
    },
    SetSelectedOptionValue(item) {
      this.SelectedOptionValue = JSON.parse(JSON.stringify(item))
    },
    SetSelectedOption(item) {
      this.SelectedOption = JSON.parse(JSON.stringify(item))
      this.GetOptionValuesByOptionId()
    },
    async GetOptionValuesByOptionId() {
      let _this = this
      let getAllOptionValuesByOptionId = new GetAllOptionValuesByOptionId(_this)
      let data = {
        optionId: this.SelectedOption.optionId
      }
      getAllOptionValuesByOptionId.setParams(data)
      await getAllOptionValuesByOptionId.fetch(function (content) {
        _this.OptionValues = content
      }, function (error) {
        console.log(error)
      })
    },
    async UpdateOption() {
      let _this = this
      let updateOption = new UpdateOption(_this)
      let data = {
        optionId: this.SelectedOption.optionId,
        name: this.SelectedOption.name,
        type: this.SelectedOption.type
      }
      updateOption.setData(data)
      await updateOption.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `ویژگی بروزرسانی شد`,
          },
        })
        _this.SelectedOption = null
        _this.GetAllOptions();
      }, function (error) {
        console.log(error)
      })
    },
    async DeleteOption() {
      let _this = this
      let deleteOption = new DeleteOption(_this)
      deleteOption.setParams({id: this.SelectedOption.optionId})
      await deleteOption.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `ویژگی حذف شد.`,
          },
        })
        _this.GetAllOptions();
      }, function (error) {
        console.log(error)
      })
    },
    async CreateOption() {
      if (this.optionName !== '') {

        let _this = this
        let createOption = new CreateOption(_this)
        let data = {
          name: this.optionName,
          type: 1
        }
        createOption.setData(data)
        await createOption.fetch(function (content) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `ویژگی جدید ساخته شد`,
            },
          })
          _this.GetAllOptions();
          _this.optionName = ''
        }, function (error) {
          console.log(error)
        })
      } else {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'CloseIcon',
            variant: 'danger',
            text: `لطفا نام ویژگی را وارد کنید`,
          },
        })
      }
    },
    async GetAllOptions() {
      let _this = this
      let getAllOptions = new GetAllOptions(_this)
      await getAllOptions.fetch(function (content) {
        _this.Options = content
      }, function (error) {
        console.log(error)
      })
    },
    async CreateOptionValue() {
      if (this.optionValueName !== '') {

        let _this = this
        let createOptionValue = new CreateOptionValue(_this)
        let data = {
          optionId: this.SelectedOption.optionId,
          value: this.optionValueName,
          productId: 0,
          optionValueParentId: null,
          vendorUserId: getUserData().userId
        }
        createOptionValue.setData(data)
        await createOptionValue.fetch(function (content) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `مقدار  جدید ساخته شد`,
            },
          })
          _this.GetOptionValuesByOptionId();
          _this.optionValueName = ''
        }, function (error) {
          console.log(error)
        })
      } else {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'CloseIcon',
            variant: 'danger',
            text: `لطفا نام  را وارد کنید`,
          },
        })
      }
    }

  },
}
</script>

<style scoped>

</style>
